<template>
  <div id="app" class="pcm-app">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: "app",
};
</script>
<style lang="scss">
#app {
  font-size: 14px;
  height: 100%;
  background: #979A9A;
  position: relative;
  color: #000000;
}

.pcm-app {
  width: 100%;
  max-width: 540px;
  margin: auto;
  position: relative;
  background: #979A9A;
  color: #000000;
  height: 100%;
  min-height: 100%;
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  .van-tabbar--fixed {
    position: fixed;
    bottom: 0;
    left: auto;
    max-width: 540px;
  }
}
</style>
